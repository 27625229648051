import { RouteRecordRaw } from 'vue-router';

const propertyBaseRouter = 'propertyIndex';
const propertySubRouter = {
    home: 'home',
    apartment: 'apartment',
    apartmentInfo: 'apartmentInfo',
    resident: 'resident',
    residentSet: 'residentSet',
    residentSetInfo: 'residentSetInfo',
    residentInfo: 'residentInfo',
    residentSetAccessGroup: 'residentSetAccessGroup',
    residentSetSelfAccess: 'residentSetSelfAccess',
    personnel: 'personnel',
    personnelHandle: 'personnelHandle',
    tempKeyInfo: 'tempKeyInfo',
    access: 'access',
    accessInfo: 'accessInfo',
    setAccess: 'setAccess',
    log: 'log',
    library: 'library',
    message: 'message',
    messageInfo: 'messageInfo',
    messageAdd: 'messageAdd',
    device: 'device',
    screenSaver: 'screenSaver',
    setScreenSaver: 'setScreenSaver',
    deviceInfo: 'deviceInfo',
    subscription: 'subscription',
    payment: 'payment',
    paymentInfo: 'paymentInfo',
    setting: 'setting',
    black: 'black',
    alarmRecoeds: 'alarmRecoeds',
    armingAlarm: 'armingAlarm'
};

const propertyOldCommunitySubRouter = {
    oldResident: 'oldResident',
    oldResidentAuth: 'oldResidentAuth',
    oldTempKeys: 'oldTempKeys',
    oldDeliveryAuth: 'oldDeliveryAuth',
    oldStaffAuth: 'oldStaffAuth',
    oldSetResident: 'oldSetResident',
    oldAddDeliveryAuth: 'oldAddDeliveryAuth',
    oldResidentInfo: 'oldResidentInfo',
    oldSetResidentAuth: 'oldSetResidentAuth',
    oldSetStaffAuth: 'oldSetStaffAuth',
    oldTempKeyNew: 'oldTempKeyNew',
    oldTempKeyInfo: 'oldTempKeyInfo'
};

const index = () => import('@/views/pm/index.vue');
const black = () => import('@/views/pm/black/index.vue');
const subscription = () => import('@/views/pm/subscription/index.vue');
const payment = () => import('@/views/pm/payment/index.vue');
const paymentInfo = () => import('@/views/pm/payment/info.vue');
const apartment = () => import('@/views/pm/apartment/index.vue');
const apartmentInfo = () => import('@/views/pm/apartment/info/index.vue');
const personnel = () => import('@/views/pm/personnel/index.vue');
const personnelHandle = () => import('@/views/pm/personnel/personnel-handle/index.vue');
const tempKeyInfo = () => import('@/views/pm/personnel/temp-key-info/index.vue');
const log = () => import('@/views/pm/log/index.vue');
const access = () => import('@/views/pm/access/index.vue');
const library = () => import('@/views/pm/library/index.vue');
const accessInfo = () => import('@/views/pm/access/info.vue');
const setAccess = () => import('@/views/pm/access/set.vue');
const home = () => import('@/views/pm/home/index.vue');
const resident = () => import('@/views/pm/resident/index.vue');
const residentSet = () => import('@/views/pm/resident/set.vue');
const device = () => import('@/views/pm/device/index.vue');
const deviceInfo = () => import('@/views/pm/device/components/intercom-device-info.vue');
const screenSaver = () => import('@/views/pm/device/screen-saver.vue');
const setScreenSaver = () => import('@/views/pm/device/set-screen-saver.vue');
const setting = () => import('@/views/pm/setting/index.vue');
const message = () => import('@/views/pm/message/index.vue');
const messageInfo = () => import('@/views/pm/message/info.vue');
const messageAdd = () => import('@/views/pm/message/add.vue');
const residentInfo = () => import('@/views/pm/resident/info.vue');
const residentSetInfo = () => import('@/views/pm/resident/edit-base.vue');
const residentSetAccessGroup = () => import('@/views/pm/resident/set-access-group.vue');
const residentSetSelfAccess = () => import('@/views/pm/resident/set-self-access.vue');
const alarmRecoeds = () => import('@/views/pm/alarm-records/recoeds.vue');
const armingAlarm = () => import('@/views/pm/arming-alarm/arming-alarm.vue');
// 旧PM
const oldResident = () => import('@/views/pm/old-community/resident/index.vue');
const oldResidentSet = () => import('@/views/pm/old-community/resident/module/set/set.vue');
const oldDeliveryAuth = () => import('@/views/pm/old-community/delivery-auth/index.vue');
const oldResidentAuth = () => import('@/views/pm/old-community/resident-auth/index.vue');
const oldTempKeys = () => import('@/views/pm/old-community/temp-key/index.vue');
const oldTempKeyNew = () => import('@/views/pm/old-community/temp-key/module/set/set.vue');
const oldStaffAuth = () => import('@/views/pm/old-community/staff-auth/index.vue');
const oldAddDeliveryAuth = () => import('@/views/pm/old-community/delivery-auth/module/add.vue');
const oldResidentInfo = () => import('@/views/pm/old-community/resident/module/info/index.vue');
const oldSetResidentAuth = () => import('@/views/pm/old-community/resident-auth/module/set.vue');
const oldSetStaffAuth = () => import('@/views/pm/old-community/staff-auth/module/set.vue');
const oldTempKeyInfo = () => import('@/views/pm/old-community/temp-key/module/temp-key-info/index.vue');

const routes: Array<RouteRecordRaw> = [{
    path: `/${propertyBaseRouter}`,
    component: index,
    name: propertyBaseRouter,
    redirect: `/${propertyBaseRouter}/${propertySubRouter.home}`,
    children: [
        {
            path: propertySubRouter.black,
            name: `${propertyBaseRouter}_${propertySubRouter.black}`,
            component: black
        },
        {
            path: propertySubRouter.alarmRecoeds,
            name: `${propertyBaseRouter}_${propertySubRouter.alarmRecoeds}`,
            component: alarmRecoeds
        },
        {
            path: propertySubRouter.armingAlarm,
            name: `${propertyBaseRouter}_${propertySubRouter.armingAlarm}`,
            component: armingAlarm
        },
        {
            path: propertySubRouter.subscription,
            name: `${propertyBaseRouter}_${propertySubRouter.subscription}`,
            component: subscription
        },
        {
            path: propertySubRouter.payment,
            name: `${propertyBaseRouter}_${propertySubRouter.payment}`,
            component: payment
        },
        {
            path: propertySubRouter.paymentInfo,
            name: `${propertyBaseRouter}_${propertySubRouter.paymentInfo}`,
            props: (route) => ({ id: route.query.id }),
            component: paymentInfo
        },
        {
            path: propertySubRouter.apartment,
            name: `${propertyBaseRouter}_${propertySubRouter.apartment}`,
            component: apartment
        },
        {
            path: propertySubRouter.apartmentInfo,
            name: `${propertyBaseRouter}_${propertySubRouter.apartmentInfo}`,
            component: apartmentInfo,
            props: (route) => ({ ID: route.query.ID })
        },
        {
            path: propertySubRouter.personnel,
            name: `${propertyBaseRouter}_${propertySubRouter.personnel}`,
            component: personnel,
            props: (route) => ({ type: route.query.type })
        },
        {
            path: propertySubRouter.personnelHandle,
            name: `${propertyBaseRouter}_${propertySubRouter.personnelHandle}`,
            component: personnelHandle,
            props: (route) => ({ type: route.query.type, option: route.query.option, optionUUID: route.query.optionUUID })
        },
        {
            path: propertySubRouter.tempKeyInfo,
            name: `${propertyBaseRouter}_${propertySubRouter.tempKeyInfo}`,
            component: tempKeyInfo,
            props: (route) => ({ ID: route.query.ID })
        },
        {
            path: propertySubRouter.log,
            name: `${propertyBaseRouter}_${propertySubRouter.log}`,
            component: log,
            props: (route) => ({ type: route.query.type })
        },
        {
            path: propertySubRouter.access,
            name: `${propertyBaseRouter}_${propertySubRouter.access}`,
            component: access
        },
        {
            path: propertySubRouter.library,
            name: `${propertyBaseRouter}_${propertySubRouter.library}`,
            component: library,
            props: (route) => ({ type: route.query.type })
        },
        {
            path: propertySubRouter.setAccess,
            name: `${propertyBaseRouter}_${propertySubRouter.setAccess}`,
            props: (route) => ({ id: route.query.id }),
            component: setAccess
        },
        {
            path: propertySubRouter.accessInfo,
            name: `${propertyBaseRouter}_${propertySubRouter.accessInfo}`,
            props: (route) => ({ id: route.query.id }),
            component: accessInfo
        },
        {
            path: propertySubRouter.home,
            name: `${propertyBaseRouter}_${propertySubRouter.home}`,
            component: home
        },
        {
            path: propertySubRouter.resident,
            name: `${propertyBaseRouter}_${propertySubRouter.resident}`,
            component: resident
        },
        {
            path: propertySubRouter.residentSet,
            name: `${propertyBaseRouter}_${propertySubRouter.residentSet}`,
            component: residentSet
        },
        {
            path: propertySubRouter.device,
            name: `${propertyBaseRouter}_${propertySubRouter.device}`,
            component: device,
            props: (route) => ({
                type: route.query.type,
                deviceType: route.query.deviceType
            })
        }, {
            path: propertySubRouter.deviceInfo,
            name: `${propertyBaseRouter}_${propertySubRouter.deviceInfo}`,
            props: (route) => ({ id: route.query.ID }),
            component: deviceInfo
        }, {
            path: propertySubRouter.screenSaver,
            name: `${propertyBaseRouter}_${propertySubRouter.screenSaver}`,
            component: screenSaver
        }, {
            path: propertySubRouter.setScreenSaver,
            name: `${propertyBaseRouter}_${propertySubRouter.setScreenSaver}`,
            props: (route) => ({ type: route.query.type }),
            component: setScreenSaver
        }, {
            path: propertySubRouter.setting,
            name: `${propertyBaseRouter}_${propertySubRouter.setting}`,
            props: (route) => ({ type: route.query.type }),
            component: setting
        },
        {
            path: propertySubRouter.message,
            name: `${propertyBaseRouter}_${propertySubRouter.message}`,
            component: message
        },
        {
            path: propertySubRouter.messageInfo,
            name: `${propertyBaseRouter}_${propertySubRouter.messageInfo}`,
            component: messageInfo
        },
        {
            path: propertySubRouter.messageAdd,
            name: `${propertyBaseRouter}_${propertySubRouter.messageAdd}`,
            component: messageAdd
        },
        {
            path: propertySubRouter.residentInfo,
            name: `${propertyBaseRouter}_${propertySubRouter.residentInfo}`,
            props: (route) => ({ id: route.query.id, type: route.query.type }),
            component: residentInfo
        },
        {
            path: propertySubRouter.residentSetInfo,
            name: `${propertyBaseRouter}_${propertySubRouter.residentSetInfo}`,
            props: (route) => ({ id: route.query.id }),
            component: residentSetInfo
        },
        {
            path: propertySubRouter.residentSetAccessGroup,
            name: `${propertyBaseRouter}_${propertySubRouter.residentSetAccessGroup}`,
            props: (route) => ({
                roomId: route.query.roomId,
                id: route.query.id,
                departmentId: route.query.departmentId,
                role: route.query.role
            }),
            component: residentSetAccessGroup
        },
        {
            path: propertySubRouter.residentSetSelfAccess,
            name: `${propertyBaseRouter}_${propertySubRouter.residentSetSelfAccess}`,
            props: (route) => ({ roomId: route.query.roomId, id: route.query.id, pid: route.query.pid }),
            component: residentSetSelfAccess
        },
        {
            path: propertyOldCommunitySubRouter.oldResident,
            name: `${propertyBaseRouter}_${propertyOldCommunitySubRouter.oldResident}`,
            component: oldResident,
            props: (route) => ({ state: route.query.state })
        },
        {
            path: propertyOldCommunitySubRouter.oldResidentAuth,
            name: `${propertyBaseRouter}_${propertyOldCommunitySubRouter.oldResidentAuth}`,
            component: oldResidentAuth,
            props: (route) => ({ type: route.query.type })
        },
        {
            path: propertyOldCommunitySubRouter.oldTempKeys,
            name: `${propertyBaseRouter}_${propertyOldCommunitySubRouter.oldTempKeys}`,
            component: oldTempKeys
        },
        {
            path: propertyOldCommunitySubRouter.oldDeliveryAuth,
            name: `${propertyBaseRouter}_${propertyOldCommunitySubRouter.oldDeliveryAuth}`,
            component: oldDeliveryAuth,
            props: (route) => ({ type: route.query.type })
        },
        {
            path: propertyOldCommunitySubRouter.oldStaffAuth,
            name: `${propertyBaseRouter}_${propertyOldCommunitySubRouter.oldStaffAuth}`,
            component: oldStaffAuth,
            props: (route) => ({ type: route.query.type })
        },
        {
            path: propertyOldCommunitySubRouter.oldSetResident,
            name: `${propertyBaseRouter}_${propertyOldCommunitySubRouter.oldSetResident}`,
            component: oldResidentSet,
            props: (route) => ({ id: route.query.id })
        },
        {
            path: propertyOldCommunitySubRouter.oldResidentInfo,
            name: `${propertyBaseRouter}_${propertyOldCommunitySubRouter.oldResidentInfo}`,
            component: oldResidentInfo,
            props: (route) => ({ id: route.query.id })
        },
        {
            path: propertyOldCommunitySubRouter.oldAddDeliveryAuth,
            name: `${propertyBaseRouter}_${propertyOldCommunitySubRouter.oldAddDeliveryAuth}`,
            component: oldAddDeliveryAuth,
            props: (route) => ({ type: route.query.type, opera: route.query.opera })
        },
        {
            path: propertyOldCommunitySubRouter.oldSetResidentAuth,
            name: `${propertyBaseRouter}_${propertyOldCommunitySubRouter.oldSetResidentAuth}`,
            component: oldSetResidentAuth,
            props: (route) => ({ type: route.query.type, opera: route.query.opera })
        },
        {
            path: propertyOldCommunitySubRouter.oldSetStaffAuth,
            name: `${propertyBaseRouter}_${propertyOldCommunitySubRouter.oldSetStaffAuth}`,
            component: oldSetStaffAuth,
            props: (route) => ({ opera: route.query.opera })
        },
        {
            path: propertyOldCommunitySubRouter.oldTempKeyNew,
            name: `${propertyBaseRouter}_${propertyOldCommunitySubRouter.oldTempKeyNew}`,
            component: oldTempKeyNew
        },
        {
            path: propertyOldCommunitySubRouter.oldTempKeyInfo,
            name: `${propertyBaseRouter}_${propertyOldCommunitySubRouter.oldTempKeyInfo}`,
            component: oldTempKeyInfo,
            props: (route) => ({ ID: route.query.ID })
        }
    ]
}];

export default routes;
export {
    propertyBaseRouter,
    propertySubRouter,
    propertyOldCommunitySubRouter
};
