import router, { baseSubRouter } from '@/router/index';
import eventBus from '@/util/use-event-bus/global-event-bus';

export default function goBackLogin(isDis?: boolean) {
    eventBus.emit('logOut');
    // 6.5.2免密登录需求：退出清除sessionStorage
    if (sessionStorage.getItem('token')) {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('userName');
        sessionStorage.removeItem('account');
    } else {
        localStorage.removeItem('token');
        localStorage.removeItem('userName');
        localStorage.removeItem('account');
    }
    // router.push('/').then(() => {
    //     if (isDis) {
    //         window.location.reload();
    //     }
    // });
    if (process.env.VUE_APP_NODE_ENV === 'pro') {
        if (Lang === 'he' || Lang === 'ar'
        ) {
            window.location.href = '/manager/rtl/#/login';
        } else {
            window.location.href = '/manager/#/login';
        }
    } else {
        router.push('/');
    }
}
